@import './src/scss/variables';
@import './src/scss/game';

$sizes: 5, 27;

//@each $size in $sizes {
@for $size from 5 through 27 {
  .grid-#{$size} {
    grid-template-columns: repeat($size, 40px);
  }
}

.game__container {
  display: flex;
  justify-content: center;
}

.grid {
	display: grid;
  gap: 1px;
  padding: 0;
  position: relative;

  &__cell {
    outline: 1px solid $color;
    aspect-ratio: 1 / 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.black {
      background-color: $color;
      color: $color2;

      &.error-alongside {
        background-color: $color11 !important;
        color: $color2;
      }
    }

    &.error-area:not(.black) {
      color: $color11;
    }

    &.green {
      background-color: $color10;
      color: $color2;
    }

    .number {}
  }
}

@import 'dark-theme';