@import './src/scss/variables';

.dark-theme {
	.grid__cell {
    outline: 1px solid $dark-color;
  }

  .grid__cell.black {
    background-color: $dark-color;
    color: $dark-color2;
	}

	.grid__cell.green {
		color: $dark-color2;
	}

	.grid__cell.black.error-alongside {
		color: $dark-color2;
	}
}